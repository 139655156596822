import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const Waveform = ({ audioUrl }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedLoad = debounce((url) => {
    if (wavesurfer.current) {
      wavesurfer.current.load(url);
    }
  }, 300);

  useEffect(() => {
    if (waveformRef.current && audioUrl) {
      // Initialize wavesurfer
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#ff5500',
        height: 100,
      });

      // Debounced load
      debouncedLoad(audioUrl);

      // Handle error events
      wavesurfer.current.on('error', (e) => {
        console.error('WaveSurfer Error:', e);
      });

      // Cleanup on unmount
      return () => {
        if (wavesurfer.current) {
          wavesurfer.current.destroy();
        }
      };
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (isPlaying) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleStop = () => {
    if (wavesurfer.current) {
      wavesurfer.current.stop();
      setIsPlaying(false);
    }
  };

  return (
    <div>
      <div id="waveform" ref={waveformRef}></div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <button
          onClick={handlePlayPause}
          className={`btn ${isPlaying ? 'btn-warning' : 'btn-success'} mx-2`}
        >
          {isPlaying ? <i className="fa-solid fa-pause"></i> : <i className="fa-solid fa-play"></i>}
        </button>
        <button
          onClick={handleStop}
          className="btn btn-danger"
        >
          <i className="fa-solid fa-stop"></i>
        </button>
      </div>
    </div>
  );
};

export default Waveform;
